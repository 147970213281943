<template>
  <div>
    <header>
      <el-form :inline="true" :model="form"  label-width="100px" ref="positionTime">
          <el-form-item label="通讯板编号" >
            <el-input v-model="form.deviceNo" placeholder="请输入通讯板编号"/>
          </el-form-item>
          <el-form-item label="所属代理商">
            <AgentTree
              :props="props"
              v-model="unitNos"
              style="width: 300px"
            ></AgentTree>
          </el-form-item>
          <el-form-item label="租赁状态" >
            <el-select ref="formStatus" v-model="form.status" clearable>
              <el-option
              value="2"
              label="租赁中"
               >
              </el-option>
              <el-option
              value="1"
              label="闲置"
              >
             </el-option>
            </el-select>
          </el-form-item>
          <el-button type="primary" class="ml32" @click="resetQuery()">查询</el-button>
      </el-form>
    </header>
    <section class="mb30">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="unitName" label="代理商" width="100"/>
        <el-table-column prop="storeName" label="门店" width="150"/>
        <el-table-column prop="deviceNo" label="通讯板编号"/>
        <el-table-column prop="leaseStatus" label="租赁状态">
          <template slot-scope="{ row }">
            {{ $changeObj(LEASE_STATUS)[row.leaseStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="onlineStatus" label="在线状态">
          <template slot-scope="{ row }">
            <span v-if="+row.onlineStatus === 2" class="red">离线</span>
            <span v-else>在线</span>
          </template>
        </el-table-column>
        <el-table-column prop="lastLocationTime" label="最后定位时间">
          <template slot-scope="scope">
            {{scope.row.lastLocationTime | timeToDate}}
          </template>
        </el-table-column>
        <el-table-column prop="bMSUpdateTime" label="BMS最后更新时间">
           <template slot-scope="scope">
            {{scope.row.bMSUpdateTime | timeToDate}}
          </template>
        </el-table-column>
        <el-table-column prop="offline" label="离线时长(小时)">
            <template slot-scope="scope">
            {{scope.row.offline }}
          </template>
        </el-table-column>
      </el-table>
    </section>
    <footer class="fe df">
       <el-pagination
        :page-size="20"
        layout="total, prev, pager, next"
        @current-change="currentChange"
        :total="total"/>
    </footer>
  </div>
</template>

<script>
  import {STATUS,LEASE_STATUS} from '@/js/constant';
  import {getPositionTime} from '@/api/dataStatement'
  import  AgentTree from '@/components/AgentTree';
export default {
  data() {
    return {
      // rules:{
      //     deviceNo: { required: true, message: '请输入设备编号', trigger: 'blur' },
      //     unitNos: { required: true, message: '请选择代理商', trigger: 'blur' },
      //     status: { required: true, message: '请选择租赁状态', trigger: 'blur' },
      //     },
      selectKey: '',
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      STATUS,
      LEASE_STATUS,
      total:0,
      unitNos:[],
      form:{
        unitNo:'',
        // 1617947600000454038
        // deviceNo:'1614050704674548123',
        deviceNo:null,
        status:'',
        pageSize:20,
        beginIndex:0
      },
      tableData: [],
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.formStatus.blur()
        _this.resetQuery()
      }
    };
    this.resetQuery();
  },
  methods: {
    // 页面
    currentChange(index){
       this.form.beginIndex = index - 1;
       this.getPositionTime();
    },
    //查询前重置页面编号等状态
    resetQuery() {
      this.form.beginIndex = 0;
      this.getPositionTime();
    },
    // 查询
    async getPositionTime(){
      if(!this.form.deviceNo){
        this.form.deviceNo = null;
      }
      this.form.unitNo = this.unitNos[this.unitNos.length - 1];
      const params = {...this.form};
      try{
        const res = await getPositionTime(params);
        this.total = res.mdata.total;
        this.tableData = res.mdata.leaseLocationCollectDtos;
      }catch(error){
        console.log(error);
      }
    }
  },
  components: {
    AgentTree,
  }
};
</script>

<style scoped lang="less">
header {
  display: flex;
  .headBox {
    display: flex;
    align-items: center;

    width: 300px;
    .label {
      width: 120px;
    }
  }
}
</style>
